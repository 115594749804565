.container {
    position: relative; /* Garante que o elemento filho possa ser posicionado em relação a este */
    overflow: hidden; /* Garante que o conteúdo dentro do contêiner não vaze */
    background: rgb(4, 16, 22);
    background: radial-gradient(circle, rgba(4, 16, 22, 1) 0%, rgba(4, 44, 64, 1) 100%);
    padding-top: 4rem;
    
    @media (max-width: 768px) {
        background-image: none;
        background-color: #051923;
    }

    &:after {
        content: ""; /* Adiciona um pseudo-elemento para criar o efeito de animação */
        position: absolute; /* Define a posição do pseudo-elemento */
        top: 0;
        left: 0;
        width: 100%; /* Garante que o pseudo-elemento cubra toda a largura do contêiner */
        height: 100%; /* Garante que o pseudo-elemento cubra toda a altura do contêiner */
        background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(255, 255, 255, 0.1), rgba(0, 0, 0, 0)); /* Cria um gradiente linear para o efeito de animação */
        animation: moveBackground 35s linear infinite; /* Adiciona a animação de movimento */
    }
}

.box {
    position: relative;
    max-width: 1000px;
    padding: 5%;
    margin-left: 8rem;

    @media (max-width: 768px) {
        max-width: 600px;
        margin-left: 0;
        padding-top: 8rem;
    }

    p {
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #fffff9;
        font-size: 18px;
        word-break: normal;


        @media (max-width: 768px) {
            padding-top:2rem ;
            font-size: 16px;
        }
    }
}

.line {
    .title {
        font-family: "Sarabun", sans-serif;
        font-weight: 800;
        font-size: 64px;
        font-style: normal;
        margin: 0;
        padding: 0;
        color: #FFFF;

        @media (max-width: 768px) {
            font-size: 35px;
        }

        @media (max-width: 1400px) {
            font-size: 45px;
        }
    }
}

@keyframes typing {
    from {
        width: 0;
    }

    to {
        width: 100%;
    }
}

@keyframes moveBackground {
    from {
        transform: translateX(-100%); /* Move o gradiente da esquerda para fora da tela */
    }
    to {
        transform: translateX(100%); /* Move o gradiente para a direita, para fora da tela */
    }
}