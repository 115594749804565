
.Container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    .Values{
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
}

.Box{

    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    background-size: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-image: url('./assets/Back.png');


}
