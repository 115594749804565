// CookieConsent.scss
.cookie-consent {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: linear-gradient(90deg, #051923, #1c3b70);
    color: #fff;
    text-align: center;
    padding: 20px;
    z-index: 1000;
    box-shadow: 0px -2px 10px rgba(0, 0, 0, 0.3);
    transform: translateY(100%);
    animation: slide-up 0.5s ease-out forwards;

    .cookie-content {
        max-width: 800px;
        margin: 0 auto;

        p {
            margin: 0;
            font-size: 1.1em;
            font-weight: 300;
            line-height: 1.5;

            a {
                color: #3b82f6;
                text-decoration: underline;

                &:hover {
                    color: #fff;
                }
            }
        }

        .cookie-consent-buttons {
            display: flex;
            justify-content: center;
            gap: 10px;
            margin-top: 20px;

            button {
                background: #1e3a8a;
                color: #fff;
                border: 2px solid #1e3a8a;
                border-radius: 25px;
                padding: 12px 30px;
                cursor: pointer;
                font-size: 1em;
                font-weight: 500;
                transition: background 0.3s ease, transform 0.3s ease;

                &:hover {
                    background: #3b82f6;
                    transform: translateY(-2px);
                }

                &:active {
                    background: #1d4ed8;
                    transform: translateY(0);
                }

                &.reject {
                    background: #6d6d6d;
                    border-color: #6d6d6d;

                    &:hover {
                        background: #9d9d9d;
                    }

                    &:active {
                        background: #808080;
                    }
                }
            }
        }
    }
}

@keyframes slide-up {
    from {
        transform: translateY(100%);
    }

    to {
        transform: translateY(0);
    }
}