.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 80vh;
}

.Title {
    font-size: 3rem;
    color: #333;
    margin-bottom: 1rem;
}

.Message {
    font-size: 1.5rem;
    color: #666;
}
