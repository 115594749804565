@import '../../index.scss';
.Container {
background-color: $azul-escuro;
display: flex;
align-items: center;
justify-content: center;
position: relative;
bottom: 0;
margin-top: 3rem;
a{
    text-decoration: none;
    color: #fff;
}
}
.Box{

    display: flex;
    flex-direction: Space-between;
    margin-top: 40px;
    margin-bottom: 10px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        
    }
}
.Space{
    flex-direction: column;
    padding-top: 20px ;
    padding-bottom:20px;
    padding-left: 5%;
    width: 400px;


    @media screen and (max-width: 768px) {  
        width: 80%;
        padding: 15px 15px 15px 15px;
        margin: 15px 15px 15px 15px;
    }



}
.Title{
    color: #494949;
    font-size: 1.5rem;



}
.Text{
    color: #fff;
    font-family: "Roboto Condensed", sans-serif;
    font-optical-sizing: auto;
    font-weight: 300;
    padding:auto;

}
.Social{
    display: flex;
    font-size: 40px;
    color: #fff;
    justify-content: space-between;
    margin-top: 15px;

    @media screen and (max-width: 768px) {
        margin-top: 25px;
    
        a{
            font-size: 40px;
        }
    }
}
.Icon {
    margin-right: 5px;

    
};
