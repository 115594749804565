
.Container{
    display: flex;
    align-self: center;
    justify-content: center;
    max-width: 1600px;
    

    @media screen and (max-width: 768px) {
        align-items: center;
        align-items: center;
        justify-content: center;
        align-self: center;
        flex-direction: column;
    }
    

}
.Cards{
    display: flex;
    gap: 50px;
    

    @media screen and (max-width: 768px) {
        display: flex;
        align-items: center;
        justify-content: center;
        align-self: center;
        flex-direction: column;
        gap: 50px;
        
    }

}

h1{
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-size: 3rem;
    font-weight: 900;
    font-style: normal;
}