:root {
  --main-font: 'Inter', system-ui, Avenir, Helvetica, Arial, sans-serif ,;
  font-family: var(--main-font);
  background-color: rgb(var(--color-base));
  box-sizing: border-box;
  $base-color: #f6f9fc;
}

//PAleta de cores Azul
$azul-escuro: #051923;
$azul-escuro-medio:#003554;
$azul-medio: #006494;
$azul-claro-medio: #0582CA;
$azul-claro: #00A6FB;


body {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-width: 100vw;

} 

/* Estilos específicos para a barra de rolagem */
::-webkit-scrollbar {
  width: 5px;
  /* Largura da barra de rolagem */
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #585858;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #424242;
}


@import url('https://fonts.cdnfonts.com/css/helvetica-neue-55'); //Helvetica
@import url('https://fonts.googleapis.com/css2?family=Roboto+Slab:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@200..700&display=swap'); //Oswald
@import url('https://fonts.googleapis.com/css2?family=Sarabun:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); //Sarabun
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&display=swap'); //Roboto Condensed