@import '../../../../index.scss';

.Container {
    height: 350px; 
    width: 400px;
    display: flex;
    justify-content:center;
    margin-top :-1rem;
    

    box-shadow: 1px 1px 10px #4f87db;

    @media (max-width: 768px) {
        height: 200px; 
        width: 350px;
    }

    @media (max-width: 1400px) {
        height: 280px;
        width: 350px;
    }
;
    
}

.Container:hover {
    scale: 1.03;
    transition: 0.5s;
}

.Container:not(:hover) {
    transition: 0.18s;
    scale: 1;
}

.Box {
    
    width: 320px;
    height: 180px;
    margin-top: 10px;


    p {
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #4f4f4f;
        font-size: 16px;
        word-break: normal;
        max-width: 300px;
        position: absolute;
        bottom: 70px;
    
    
    }
}
.Button {
    
    background: $azul-escuro;
    padding: 1px;
    color: azure;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-size: 1rem;
    font-style: normal;
    border: none;
    box-shadow: 1px 1px 1px rgba(255, 255, 255, 0.25);

    padding: 10px;
    cursor: pointer;
    position: absolute;
    bottom: 15px;
    left:20px;
}

h4 {
    color: $azul-escuro;
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-size: 1rem;
    font-weight: 400;
    font-style: normal;
}
h2 {
    font-family: "Roboto Slab", serif;
    font-optical-sizing: auto;
    font-weight: 900;
    font-style: normal;
    color: $azul-escuro-medio;
}

