* {
    margin: 0;
    padding: 0;


}



a {
    text-decoration: none;
    font-family: "Sarabun", sans-serif;
    font-weight: 600;

    @media screen and (max-width: 1400px) {
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #fffff9;
        font-size: 14px;
    }
}

.header {
    
    background: #ffffff;
    padding-inline: 16px;
    justify-content: center;
    
    @media screen and (min-width: 768px) {
    border: #f5f5f5 solid 1px;
    height: 70px;
    box-shadow: 0 4px 8px 0 rgba(0, 12, 77, 0.535), 0 6px 15px 0 rgba(1, 15, 44, 0.535);
    position: absolute;
    z-index: 9999;
    width: 80%;
    top: 30px;
    left: 10%;
    border-radius: 10px;

        width: 80%;
        top: 18px;
        left: 10%;
        height: 60px;
        
    }



    @media screen and (max-width: 768px) {
        position: fixed;
        width: 95%;
        z-index: 9999;
        padding-left: 10px;
    }


    @media screen and (min-width: 768px) {
        display: flex;
        align-items: center;
    }
}

.nav {
    max-width: 1680px;
    height: 90px;
    display: flex;
    align-items: center;
    gap: 20px;

    @media screen and (max-width: 768px) {
        justify-content: space-between;

    }

    display: flex;

    justify-content: space-between;
    width: 100%;




}

.logo {
    font-size: 48px;
    display: flex;
    align-items: center;


    h5 {
        font-family: "Roboto Slab", serif;
        font-optical-sizing: auto;
        font-size: 2rem;
        font-weight: 500;
        color: #003554;

        @media screen and (min-width: 768px) {
            display: none;
        }

    }


    img {

        width: 50px;
        height: 50px;
        @media screen and (max-width: 1400px) {
            width: 40px;
            height: 40px;
            
        }



    }
}

.nav-list {
    display: flex;
    gap: 32px;
    list-style: none;
}

.nav-list a {
    font-size: 18px;
    color: #070707;

    @media screen and (max-width: 768px) {
        color: #fff;

    }

    padding-block: 16px;
}

.hero {
    height: calc(100vh - 70px);
    background: center
}

.hamburger {
    display: none;
    border: none;
    background: none;
    border-top: 3px solid #003554;
    cursor: pointer;
}

.hamburger::after,
.hamburger::before {
    content: " ";
    display: block;
    width: 30px;
    height: 3px;
    background: #003554;
    margin-top: 5px;
    position: relative;
    transition: 0.3s;
    
}

@media (max-width: 750px) {
    .hamburger {
        display: block;
        z-index: 1;
    }

    .nav-list {
        position: fixed;
        top: 0;
        right: 0;
        width: 60vw;
        height: 100vh;
        background: #005279;
        clip-path: circle(100px at 90% -15%);
        transition: 1s ease-out;
        box-shadow: 0 4px 8px 0 rgb(0, 0, 0), 0 6px 15px 0 rgba(0, 46, 138, 0.535);

        display: flex;
        padding-top: 100px;
        flex-direction: column;
        align-items: end;
        pointer-events: none;
    }

    .nav-list a {
        font-size: 24px;
        padding-right: 10px;
        opacity: 0;
        font-family: "Sarabun", sans-serif;
        font-weight: 700;


    }



    .nav-list li:nth-child(1) a {
        transition: 0.5s 0.2s;

    }

    .nav-list li:nth-child(2) a {
        transition: 0.5s 0.4s;
    }

    .nav-list li:nth-child(3) a {
        transition: 0.5s 0.6s;
    }

    .nav-list li:nth-child(4) a {
        transition: 0.5s 0.8s;
    }

    .nav-list li:nth-child(5) a {
        transition: 0.5s 0.10s;
    }

    /* Estilos ativos */

    .nav.active .nav-list {
        clip-path: circle(1500px at 90% -15%);
        pointer-events: all;
    }

    .nav.active .nav-list a {
        opacity: 1;
    }

    .nav.active .hamburger {
        position: fixed;
        top: 26px;
        right: 16px;
        border-top-color: transparent;
    }

    .nav.active .hamburger::before {
        transform: rotate(135deg);
    }

    .nav.active .hamburger::after {
        transform: rotate(-135deg);
        top: -7px;
    }
}