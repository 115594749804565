.Container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 1600px;
    margin: 0 auto;


    section{
        position : relative;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        max-width: 1200px;
        margin: 0 auto;
    }

}