.Container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 1000px;
    margin: 0 auto;
    background-image: url("./Back.png");
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;

    @media screen and (max-width: 768px) {
        width: 100%;
        padding: 1rem;

    }
    

}

.Box {
    display: flex;
    padding-top: 4rem;
    justify-content: center;
    margin: 0;

    @media screen and (max-width: 768px) {
        flex-direction: column;
    }
}

.Header {
    width: 500px;

    @media screen and (max-width: 768px) {
        width: 100%;
        align-items: center;
        justify-content: center;
    }

    p {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #707070;
        font-size: 18px;
        word-break: normal;

        @media screen and (max-width: 768px) {
            font-size: 16px;
            max-width: 350px;
        }
    }

    h5 {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #000000;
        font-size: 16px;
        word-break: normal;
        margin-bottom: 2rem;

        @media screen and (max-width: 768px) {
            font-size: 18px;
            max-width: 350px;
        }
    }
}

.image {
    margin: 0;
    padding: 0;
    width: 500px;
    display: flex;
    align-items: center;
    justify-content: end;

    @media screen and (max-width: 768px) {
        display: none;

}
}
.Content {
    ul {

        li {
            display: flex;
            list-style: none;
            justify-content: start;
            gap: 1rem;
            margin-bottom: 1rem;

            font-family: "Roboto Condensed", sans-serif;
            font-optical-sizing: auto;
            font-weight: 400;
            color: #000000;
            font-size: 16px;

        }
    }

    .icon {
        font-size: 1.5rem;
        color: rgb(0, 50, 59);
    }

}

img {
    width: 450px;
    height: auto;

}

h2 {
    font-family: "Sarabun", sans-serif;
    font-weight: 800;
    font-size: 38px;
    font-style: normal;
    margin-bottom: 1rem;
    padding: 0;
    color: linear-gradient(90deg, rgba(1, 0, 17, 1) 0%, rgba(0, 0, 54, 1) 23%, rgba(0, 85, 102, 1) 100%);


    @media screen and (max-width: 768px) {
        font-size: 36px;
    }
}