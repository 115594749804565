.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 3rem;

    h2{
        font-family: "Sarabun", sans-serif;
        font-weight: 800;
        font-size: 30px;
        font-style: normal;
        padding: 0;
        color: linear-gradient(90deg, rgba(1,0,17,1) 0%, rgba(0,0,54,1) 23%, rgba(0,85,102,1) 100%);
        margin-top: 2rem;
    }
    p{
        font-family: "Roboto Condensed", sans-serif;
        font-optical-sizing: auto;
        font-weight: 400;
        color: #000000;
        font-size: 18px;
        word-break: normal;
        margin-top: 1rem;
        text-align: center;
        max-width: 250px;
    }
}

.Box {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4rem;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        
    }

}
.Circle {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Icon {
    font-size: 62px;
    color: #003554;
    display: flex;
    padding-top: 1.2rem;
    padding-left: 0.5rem;

}
.ProgressBar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    position: absolute;

}

.Progress {
    position: absolute; /* Adiciona posicionamento absoluto para sobrepor a barra de progresso */
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 5px solid transparent;
    border-top-color: #003554;
    animation: spin 2s linear infinite;
    transform-origin: center;
    

}


@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}