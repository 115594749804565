.Container{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    max-width: 1600px;
    margin: 0 auto;
    margin-bottom: 20px;

}

.Box{
    display: flex;
    align-self: center;
    section{
        gap: 50px;
        display: flex;
        justify-content: space-between;

        @media screen and (max-width: 768px) {
            flex-direction: column;
        }
    }
    
}
